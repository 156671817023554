import { createActions } from "redux-actions";

const { insuransers } = createActions({
  INSURANSERS: {
    GET_INSURANSERS: (page) => ({ page }),
    GET_INSURANSERS_RESPONSE: (listInsuransers) => listInsuransers,

    GET_BUDGET_ALL: (filters) => ({ filters }),
    GET_BUDGET_ALL_RESPONSE: (budgetAll) => budgetAll,

    GET_ALL_INSURANSERS: () => ({}),
    GET_ALL_INSURANSERS_RESPONSE: (listAllInsuransers) => listAllInsuransers,

    GET_ADMINS_TERMS_CONDITIONS: (page) => ({ page }),
    GET_ADMINS_TERMS_CONDITIONS_RESPONSE: (listAdminsTerms) => listAdminsTerms,

    RECHARGE_BUDGET: (insuranceCarrier) => ({ insuranceCarrier }),
    RECHARGE_BUDGET_RESPONSE: (newBudget) => newBudget,

    CREATE_INSURANCE: (insurance) => ({ insurance }),
    CREATE_INSURANCE_RESPONSE: (insurance) => insurance,

    UPDATE_INSURANCE: (emailAdmin) => ({ emailAdmin }),
    UPDATE_INSURANCE_RESPONSE: (insurance) => insurance,

    DELETE_INSURANCE: (insuranceKey) => ({ insuranceKey }),
    DELETE_INSURANCE_RESPONSE: (insurance) => insurance,
  },
});

export default insuransers;
