import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  filters: {
    quantity: 25,
    rol: "insurance",
  },
  loading: {
    insuransers: false,
    updateInsuransers: false,
    rechargeBudget: false,
    deleteInsurance: false,
    createInsurance: false,
    listAllInsuransers: false,
    listAdminsTerms: false,
    budgetAll: false,
  },
  error: {
    insuransers: undefined,
    updateInsuransers: undefined,
    rechargeBudget: undefined,
    deleteInsurance: undefined,
    createInsurance: undefined,
    listAllInsuransers: undefined,
    listAdminsTerms: undefined,
    budgetAll: undefined,
  },
  success: {
    rechargeBudget: false,
    updateInsuransers: false,
    insuransers: false,
    deleteInsurance: false,
    createInsurance: false,
    listAllInsuransers: false,
    listAdminsTerms: false,
    budgetAll: undefined,
  },
};

const reducer = handleActions(
  {
    INSURANSERS: {
      GET_INSURANSERS: (state) => ({
        ...state,
        loading: { ...state.loading, insuransers: true },
        success: { ...state.success, insuransers: undefined },
        error: { ...state.error, insuransers: undefined },
      }),
      GET_INSURANSERS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, insuransers: false },
            success: {
              ...state.success,
              insuransers: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, insuransers: message },
            success: { ...state.success, insuransers: undefined },
            loading: { ...state.loading, insuransers: false },
          };
        },
      },
      GET_BUDGET_ALL: (state) => ({
        ...state,
        loading: { ...state.loading, budgetAll: true },
        success: { ...state.success, budgetAll: undefined },
        error: { ...state.error, budgetAll: undefined },
      }),
      GET_BUDGET_ALL_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, budgetAll: false },
            success: {
              ...state.success,
              budgetAll: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, budgetAll: message },
            success: { ...state.success, budgetAll: undefined },
            loading: { ...state.loading, budgetAll: false },
          };
        },
      },
      GET_ALL_INSURANSERS: (state) => ({
        ...state,
        loading: { ...state.loading, listAllInsuransers: true },
        success: { ...state.success, listAllInsuransers: undefined },
        error: { ...state.error, listAllInsuransers: undefined },
      }),
      GET_ALL_INSURANSERS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listAllInsuransers: false },
            success: {
              ...state.success,
              listAllInsuransers: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listAllInsuransers: message },
            success: { ...state.success, listAllInsuransers: undefined },
            loading: { ...state.loading, listAllInsuransers: false },
          };
        },
      },

      GET_ADMINS_TERMS_CONDITIONS: (state) => ({
        ...state,
        loading: { ...state.loading, listAdminsTerms: true },
        success: { ...state.success, listAdminsTerms: undefined },
        error: { ...state.error, listAdminsTerms: undefined },
      }),
      GET_ADMINS_TERMS_CONDITIONS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listAdminsTerms: false },
            success: {
              ...state.success,
              listAdminsTerms: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listAdminsTerms: message },
            success: { ...state.success, listAdminsTerms: undefined },
            loading: { ...state.loading, listAdminsTerms: false },
          };
        },
      },

      RECHARGE_BUDGET: (state) => ({
        ...state,
        loading: { ...state.loading, rechargeBudget: true },
        success: { ...state.success, rechargeBudget: undefined },
        error: { ...state.error, rechargeBudget: undefined },
      }),
      RECHARGE_BUDGET_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, rechargeBudget: false },
            success: {
              ...state.success,
              rechargeBudget: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, rechargeBudget: message },
            success: { ...state.success, rechargeBudget: undefined },
            loading: { ...state.loading, rechargeBudget: false },
          };
        },
      },

      UPDATE_INSURANCE: (state) => ({
        ...state,
        loading: { ...state.loading, updateInsuransers: true },
        success: { ...state.success, updateInsuransers: undefined },
        error: { ...state.error, updateInsuransers: undefined },
      }),
      UPDATE_INSURANCE_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, updateInsuransers: false },
            success: {
              ...state.success,
              updateInsuransers: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateInsuransers: message },
            success: { ...state.success, updateInsuransers: undefined },
            loading: { ...state.loading, updateInsuransers: false },
          };
        },
      },

      CREATE_INSURANCE: (state) => ({
        ...state,
        loading: { ...state.loading, createInsurance: true },
        success: { ...state.success, createInsurance: undefined },
        error: { ...state.error, createInsurance: undefined },
      }),
      CREATE_INSURANCE_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, createInsurance: false },
            success: {
              ...state.success,
              createInsurance: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, createInsurance: message },
            success: { ...state.success, createInsurance: undefined },
            loading: { ...state.loading, createInsurance: false },
          };
        },
      },

      DELETE_INSURANCE: (state) => ({
        ...state,
        loading: { ...state.loading, deleteInsurance: true },
        success: { ...state.success, deleteInsurance: undefined },
        error: { ...state.error, deleteInsurance: undefined },
      }),
      DELETE_INSURANCE_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, deleteInsurance: false },
            success: {
              ...state.success,
              deleteInsurance: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, deleteInsurance: message },
            success: { ...state.success, deleteInsurance: undefined },
            loading: { ...state.loading, deleteInsurance: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
