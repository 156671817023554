import { message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import iconAdmin from "../../../assets/icon/icon-admin-table.png";
import iconOpen from "../../../assets/icon/icon-plus.svg";
import { user as UsersActions } from "../../../services/users/actions";
import CreateUser from "./components/createUser";
import EditUser from "./components/editUser";
import CustomTable from "./tables";
import Loader from "../../../components/loader";

const initialColumnOrders = {
  name: {
    column: "person.name",
    value: "ASC",
  },
};

function AdminUserList({ match }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [detail, setDetail] = useState();
  const [userToEdit, setUserToEdit] = useState(undefined);
  const [createUser, setCreateUser] = useState(false);
  const [page, setPage] = useState(0);
  const [quantity] = useState(10);
  const [filter] = useState(null);
  const [orderColumns, setOrderColumns] = useState(initialColumnOrders);
  const [activeOrderColumns, setActiveOrderColumns] = useState("name");

  const users = useSelector((state) => state.users);

  useEffect(() => {
    setDetail(match.params.userKey);
  }, [match.params]);

  useEffect(() => {
    if (!users.loading.users && !users.success.users && !users.error.users) {
      dispatch(
        UsersActions.getUsers(page, quantity, orderColumns[activeOrderColumns])
      );
    }
  }, [
    users,
    dispatch,
    page,
    filter,
    orderColumns,
    activeOrderColumns,
    quantity,
  ]);

  useEffect(() => {
    if (users.success.inactive) {
      message.success("Usuario inactivado exitosamente");
      dispatch(
        UsersActions.getUsers(page, quantity, orderColumns[activeOrderColumns])
      );
    }

    if (users.error.inactive) message.error("No se pudo inactivar el usuario");

    if (users.success.edit)
      dispatch(
        UsersActions.getUsers(page, quantity, orderColumns[activeOrderColumns])
      );

    if (users.success.create) {
      dispatch(
        UsersActions.getUsers(page, quantity, orderColumns[activeOrderColumns])
      );
    }
  }, [users, dispatch, page, quantity, orderColumns, activeOrderColumns]);

  const sort = (columnName) => {
    const newOrder = orderColumns;

    Object.keys(newOrder).map((columnkey) => {
      if (columnkey !== columnName)
        return (newOrder[columnkey].value = undefined);
      if (newOrder[columnkey].value === undefined)
        return (newOrder[columnkey].value = "ASC");

      if (newOrder[columnkey].value === "ASC")
        return (newOrder[columnkey].value = "DESC");

      if (newOrder[columnkey].value === "DESC")
        return (newOrder[columnkey].value = undefined);

      return columnkey;
    });

    setOrderColumns(newOrder);
    setActiveOrderColumns(columnName);
    dispatch(UsersActions.getUsers(page, quantity, newOrder[columnName]));
  };

  const inactiveUser = (user) => {
    dispatch(UsersActions.inactivateUser(+user.id));
  };

  const openDetail = (user) => {
    history.push(`/users/${user.userKey}`);
  };

  const closeDetail = () => {
    history.push(`/users`);
    setDetail(undefined);
  };

  const closeCreateUser = () => {
    setCreateUser(false);
  };

  const editUser = (user) => {
    setUserToEdit(user);
  };

  const closeUserToEdit = () => {
    setUserToEdit(undefined);
  };

  return (
    <div className="agents">
      <div className="agents__head">
        <h1 className="agents__title">
          <img src={iconAdmin} alt="" />
          {t("label.adminUserListTitle")}
        </h1>

        <Modal visible={!!userToEdit} onCancel={closeUserToEdit}>
          <EditUser user={userToEdit} close={closeUserToEdit} />
        </Modal>

        <Modal
          visible={createUser}
          onCancel={closeCreateUser}
          className="modal-create-user"
        >
          <CreateUser close={closeCreateUser} />
        </Modal>

        <Modal visible={!!detail} onCancel={() => closeDetail()}>
          Detail must showed
        </Modal>

        <figure
          className="agents__head__open-modal"
          onClick={() => setCreateUser(true)}
        >
          <img src={iconOpen} alt="" />
        </figure>
      </div>
      <div className="agents__tabs">
        {users.success.users && (
          <CustomTable
            data={users.success.users}
            openDetail={openDetail}
            setPage={setPage}
            sortColumns={orderColumns}
            sort={sort}
            edit={editUser}
            inactive={inactiveUser}
          />
        )}
      </div>
      {users.loading.users && <Loader />}
    </div>
  );
}

export default AdminUserList;
