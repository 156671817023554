/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Button, Input, Pagination, Popover, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import ModalLoader from "components/loader/ModalLoader";
import offersActions from "services/offers/actions";
import calendarSvg from "assets/images/svg-calendar.svg";
import closeSvg from "assets/images/close.svg";
import searchSvg from "assets/images/Search-purple.svg";
import moment from "moment";
import { DayPicker } from "react-day-picker";

const OffersSent = () => {
  const [range, setRange] = useState(undefined);
  const [insuranceKey, setObjectSearch] = useState(undefined);
  const dispatch = useDispatch();
  const { listOffers } = useSelector((state) => state.offers.success);
  const { listOffers: listOffersLoading } = useSelector(
    (state) => state.offers.loading
  );
  const [isListInsuransers, setListInsuransers] = useState();
  const [page, setPage] = useState(1);

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const newList = [];
    for (let index = 0; index < listOffers?.items?.length; index++) {
      const element = listOffers?.items[index];
      newList.push({
        numberPolicy: element?.id,
        key: element?.id,
        nameAgent: element?.agent?.user?.person?.name,
        priceOffer: element?.insuranceAmount,
        dateSent: element?.createdAt,
        isQuote: element?.hasQuotation,
        insuranceSent: element?.insuranceCarrier?.name,
      });
    }
    setListInsuransers(newList);
  }, [listOffers]);

  useEffect(() => {
    dispatch(offersActions.getOffers({ page: 1 }));
  }, [dispatch]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
    if (range !== undefined) {
      const values = {
        page: pageNumber,
        insuranceKey,
        startDate: formatDate(range.from),
        endDate: formatDate(range.to),
      };
      dispatch(offersActions.getOffers(values));
    } else {
      const values = { insuranceKey, page: pageNumber };
      dispatch(offersActions.getOffers(values));
    }
  };

  const onClearRange = () => {
    setRange(undefined);
    const values = { page: 1, insuranceKey };
    dispatch(offersActions.getOffers(values));
  };

  useEffect(() => {
    if (range?.to !== undefined) {
      setPage(1);
      const values = {
        page: 1,
        insuranceKey,
        startDate: formatDate(range.from),
        endDate: formatDate(range.to),
      };
      dispatch(offersActions.getOffers(values));
    }
  }, [range, dispatch, insuranceKey]);

  const handleWriting = (e) => {
    setObjectSearch(e.target.value);
  };

  const handleSearch = (name) => {
    setPage(1);
    if (range !== undefined) {
      const values = {
        page: 1,
        insuranceKey,
        startDate: formatDate(range.from),
        endDate: formatDate(range.to),
      };
      dispatch(offersActions.getOffers(values));
    } else {
      const values = { page: 1, insuranceKey: name };
      dispatch(offersActions.getOffers(values));
    }
  };

  const handleClear = () => {
    setPage(1);
    setObjectSearch(undefined);
    if (range !== undefined) {
      const values = {
        page: 1,
        startDate: formatDate(range.from),
        endDate: formatDate(range.to),
      };
      dispatch(offersActions.getOffers(values));
    } else {
      const values = { page: 1 };
      dispatch(offersActions.getOffers(values));
    }
  };

  return (
    <div className="agents insuransers">
      <div className="insuransers-head">
        <h1 className="agents__title">
          Ofertas enviadas por parte de las aseguradoras
        </h1>
        <div className="filters">
          <Input
            placeholder="Busca por nombre de agente"
            className="search-line"
            value={insuranceKey}
            onChange={(e) => handleWriting(e)}
            suffix={
              <>
                {insuranceKey !== "" && (
                  <Button onClick={() => handleClear()}>
                    <img
                      src={closeSvg}
                      className="site-form-item-icon"
                      alt="searcg"
                    />
                  </Button>
                )}
                <Button onClick={() => handleSearch(insuranceKey)}>
                  <img
                    src={searchSvg}
                    className="site-form-item-icon"
                    alt="searcg"
                  />
                </Button>
              </>
            }
          />
          <Popover
            trigger="click"
            content={
              <DayPicker
                numberOfMonths={2}
                mode="range"
                selected={range}
                onSelect={setRange}
              />
            }
            title={<h2 className="sub-title">Rango de fechas</h2>}
          >
            <div className="button-calendar">
              <img src={calendarSvg} alt="calendar" className="calendar" />
              {range?.to !== undefined
                ? `${formatDate(range.from)} / ${formatDate(range.to)}`
                : "aaaa-mm-dd / aaaa-mm-dd"}
              {range?.to !== undefined && (
                <Button onClick={() => onClearRange()}>
                  <img
                    src={closeSvg}
                    className="site-form-item-icon"
                    alt="searcg"
                  />
                </Button>
              )}
            </div>
          </Popover>
        </div>
      </div>
      <div className="insuransers_table">
        {listOffersLoading && <ModalLoader />}
        <Table dataSource={isListInsuransers}>
          <Column
            title="Numero de póliza"
            dataIndex="numberPolicy"
            key="numberPolicy"
            className="item"
            width={120}
          />
          <Column
            title="Nombre del agente de la póliza"
            dataIndex="nameAgent"
            key="nameAgent"
            className="item-name"
          />
          <Column
            title="Precio de la oferta"
            key="priceOffer"
            className="item"
            render={(_, record) => {
              return <span>${record.priceOffer}USD</span>;
            }}
          />
          <Column
            title="Fecha del envio de la oferta"
            key="dateSent"
            className="item"
            render={(_, record) => {
              return (
                <span>
                  {moment(record.dateSent).locale("es-mx").format("DD-MM-YYYY")}
                </span>
              );
            }}
          />
          <Column
            title="Cotizada por el agente"
            key="isQuote"
            className="item"
            render={(_, record) => {
              return <span>{record.isQuote ? "Si" : "No"}</span>;
            }}
          />
          <Column
            title="Aseguradora que envio la oferta"
            dataIndex="insuranceSent"
            key="insuranceSent"
            className="item"
          />
        </Table>
        {listOffers?.meta?.totalPages >= 1 && (
          <Pagination
            defaultCurrent={page}
            total={listOffers?.meta?.totalItems}
            pageSize={listOffers?.meta?.itemsPerPage}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};

export default OffersSent;
