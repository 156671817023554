/* eslint-disable no-plusplus */
import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Button } from "antd";
import excelPng from "assets/images/excel.png";
import { instanceOf, string } from "prop-types";

const Excelexport = ({ data, type }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async (excelData) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const datas = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(datas, `${type}${fileExtension}`);
  };

  return (
    <Button
      className="button button-primary button-ecxel"
      onClick={() => exportToExcel(data)}
    >
      <span>Descargar Excel</span>
      <img src={excelPng} alt="ecxel" className="logo-ecxel" />
    </Button>
  );
};
Excelexport.propTypes = {
  data: instanceOf(Object).isRequired,
  type: string.isRequired,
};
export default Excelexport;
