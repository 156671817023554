import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import insuransersActions from "services/insuransers/actions";
import ListInvoices from "./Tabs/ListInvoices";
import PiaPay from "./Tabs/PiaPay";

const { TabPane } = Tabs;

const BillingList = () => {
  const dispatch = useDispatch();
  const { budgetAll } = useSelector((state) => state.insuransers.success);

  useEffect(() => {
    dispatch(insuransersActions.getBudgetAll());
  }, [dispatch]);
  return (
    <div className="agents insuransers">
      <div className="insuransers-head">
        <h1 className="agents__title">Listado de facturacion</h1>
      </div>
      <p className="body-regular">
        Saldo que debe tener Stripe:{" "}
        <span className="body-bold">${budgetAll?.total} USD</span>
      </p>

      <Tabs defaultActiveKey="pay">
        <TabPane tab="Facturas pendientes" key="pay">
          <PiaPay />
        </TabPane>
        <TabPane tab="Facturas pagadas" key="Active">
          <ListInvoices />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default BillingList;
