/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import offersActions from "services/offers/actions";
import { useDispatch, useSelector } from "react-redux";
import insuransersActions from "services/insuransers/actions";
import { Button, Steps, Form, Select, message, Input, Progress } from "antd";
import Loader from "components/loader";
import { API_URL } from "../../../common/config/config";
import * as Token from "../../../common/storage/Token";
import Excelexport from "./ExportData";

const { Step } = Steps;
const { Option } = Select;

const ExcelReader = () => {
  const [options, setOptions] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [isSuccesSend, setSuccesSend] = useState([]);
  const [isErrorSend, setErrorSend] = useState([]);
  const [isArrayNotFound, setArrayNotFound] = useState([]);
  const [isArrayFound, setArrayFound] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0); // Estado para manejar el progreso
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { listOffersPiaccess } = useSelector((state) => state.offers.success);
  const { listAllInsuransers } = useSelector(
    (state) => state.insuransers.success
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (loading) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [loading]);

  function compareArrays(arrayExcel, arrayBD) {
    const notFound = [];
    const isFound = [];
    for (let i = 0; i < arrayExcel?.length; i++) {
      let found = false;
      for (let j = 0; j < arrayBD?.length; j++) {
        if (`${arrayExcel[i].IdentifierPolicy}` === `${arrayBD[j].policyId}`) {
          found = true;
          isFound.push(arrayBD[j]);
          break;
        }
      }
      if (!found) {
        notFound.push(arrayExcel[i]);
      }
    }
    return { notFound, isFound };
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      if (rows?.length > 0) {
        const headers = rows[0];
        // Filtrar los rows vacíos
        const datas = rows.slice(1).filter((row) => {
          // Solo mantenemos filas que tengan al menos un valor no vacío
          return row.some(
            (cell) => cell !== null && cell !== undefined && cell !== ""
          );
        });

        const formattedData = datas.map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index];
          });
          return obj;
        });

        setExcelData(formattedData);
        if (formattedData?.length >= 1) {
          setCurrentStep(1);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    dispatch(offersActions.getOffersPiaccess());
    dispatch(insuransersActions.getAllInsuransers());
  }, [dispatch]);

  useEffect(() => {
    if (excelData?.length >= 1) {
      const { notFound, isFound } = compareArrays(
        excelData,
        listOffersPiaccess?.items
      );
      setArrayNotFound(notFound);
      setArrayFound(isFound);
    }
  }, [excelData, listOffersPiaccess]);

  useEffect(() => {
    const listOptions = [];
    for (let index = 0; index < listAllInsuransers?.length; index++) {
      const element = listAllInsuransers[index];
      listOptions.push({
        value: element?.key,
        label: element?.name,
        logo: element?.logoColor,
      });
    }
    setOptions(listOptions);
  }, [listAllInsuransers]);

  async function sendOfertPolicy(dataOfert, amount, insuranceKey) {
    const token = await Token.get();
    setLoading(true); // Inicia la barra de carga
    setProgress(0);
    const errorSend = [];
    const succesSend = [];
    const url = "/pia/admin/offer/create";

    const sum = 100 / dataOfert.length;
    let acum = 0;
    for (let index = 0; index < dataOfert.length; index++) {
      acum += sum;
      setProgress(acum);
      const element = dataOfert[index];
      const params = {
        insuranceKey,
        policies: [
          {
            amount,
            branch: "AUTOS",
            policyNumber: element.policyNumber,
          },
        ],
      };
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(params),
      };
      try {
        const response = await fetch(`${API_URL}${url}`, requestOptions);
        if (response.ok) {
          succesSend.push(element);
        } else {
          element.MotivoDelError = `La póliza ${element.policyNumber}, ya fue ofertada.`;
          errorSend.push(element);
        }
      } catch (error) {
        element.MotivoDelError = `La póliza ${element.policyNumber}, ya fue ofertada.`;
        errorSend.push(element);
      }
    }
    setLoading(false);
    setCurrentStep(3);
    return { succesSend, errorSend };
  }

  const onFinish = async (values) => {
    const { succesSend, errorSend } = await sendOfertPolicy(
      isArrayFound,
      values.amount,
      values.insuranceKey
    );
    setSuccesSend(succesSend);
    setErrorSend(errorSend);
  };
  const onFinishFailed = () => {
    message.error("Diligencia todo el formulario");
  };
  const conicColors = {
    "0%": "#7B3BF9",
    "100%": "#00DBD0",
  };
  return (
    <div className="agents PiaaAccess">
      <h1 className="agents__title">
        Compara tu apetito con las polizas de Piaa
      </h1>
      <Steps current={currentStep}>
        <Step title="Cargar Excel" />
        <Step title="Comparación" />
        <Step title="Enviar ofertas" />
        <Step title="Resumen" />
      </Steps>
      {currentStep === 0 && (
        <>
          <h3 className="sub-title">
            Selecciona un archivo excel con tus polizas para comparar y ofertar
          </h3>
          <input
            className="input-upload button button-primary"
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
          />
        </>
      )}
      {currentStep === 1 && (
        <>
          <div className="row">
            {isArrayNotFound !== undefined && isArrayNotFound?.length >= 1 ? (
              <div className="column">
                <h3>
                  Estas {isArrayNotFound?.length} Polizas NO han sido
                  encontrados:
                </h3>
                <Excelexport type="NO found" data={isArrayNotFound} />
              </div>
            ) : (
              <div className="column">
                <h3>Todas las polizas han sido encontradas</h3>
              </div>
            )}
            {isArrayFound !== undefined && isArrayFound?.length >= 1 ? (
              <div className="column">
                <h3>
                  Estas {isArrayFound?.length} Polizas SI han sido encontrados:
                </h3>
                <Excelexport type="found" data={isArrayFound} />
              </div>
            ) : (
              <div className="column">
                <h3>Ninguna poliza ha sido encontrada</h3>
              </div>
            )}
          </div>
          {isArrayNotFound !== undefined && isArrayNotFound?.length === 0 && (
            <div className="flex-btw">
              <Button
                className="button button-primary"
                onClick={() => setCurrentStep(2)}
              >
                Continuar con las ofertas
              </Button>
            </div>
          )}
        </>
      )}
      {currentStep === 2 && (
        <>
          <div className="row">
            <div className="column">
              <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="form-items">
                  <div className="item">
                    <span className="body-bold">Aseguradora</span>
                    <Form.Item
                      name="insuranceKey"
                      rules={[
                        {
                          required: true,
                          message: "la aseguradora a ofertar",
                        },
                      ]}
                    >
                      <Select
                        className="select-custom select-general"
                        placeholder="Seleecione la aseguradora"
                      >
                        {options?.map((option) => (
                          <Option value={option.value} key={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="item">
                    <span className="body-bold">Cantidad</span>
                    <Form.Item
                      name="amount"
                      rules={[
                        {
                          required: true,
                          message: "Ingrese la cantidad a ofertar",
                        },
                      ]}
                    >
                      <Input placeholder="Cantidad a ofertar" type="number" />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item>
                  <Button className="button button-primary" htmlType="submit">
                    Enviar ofertas
                  </Button>
                </Form.Item>
              </Form>
              {loading && (
                <div className="page-progress">
                  <h3 className="h2">Progreso del envio de las ofertas</h3>
                  <Loader />
                  <h3 className="sub-title">
                    Progreso {Math.floor(progress)}%
                  </h3>
                  <Progress
                    percent={progress}
                    showInfo={false}
                    strokeColor={conicColors}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {currentStep === 3 && (
        <>
          <h2 className="body-regular">
            Cantidad de ofertas a enviar:{" "}
            <span className="body-bold">{isArrayFound?.length}</span>
            <br />
            Enviadas correctamente:{" "}
            <span className="body-bold">{isSuccesSend?.length}</span>
            <br />
            No enviadas correctamente:{" "}
            <span className="body-bold">{isErrorSend?.length}</span>
            <br />
          </h2>
          <div className="row">
            {isErrorSend !== undefined && isErrorSend?.length >= 1 ? (
              <div className="column">
                <h3>
                  Estas {isErrorSend?.length} Polizas NO se enviaron
                  correctamente
                </h3>
                <Excelexport type="NO sent" data={isErrorSend} />
              </div>
            ) : (
              <div className="column">
                <h3>Todas las polizas se enviaron correctamente</h3>
              </div>
            )}
            {isSuccesSend !== undefined && isSuccesSend?.length >= 1 && (
              <div className="column">
                <h3>
                  Estas {isSuccesSend?.length} Polizas se enviaron correctamente
                </h3>
                <Excelexport type="sent" data={isSuccesSend} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ExcelReader;
