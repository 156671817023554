import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  filters: {
    quantity: 20,
    page: 1,
  },
  loading: {
    listOffers: false,
    listFeedback: false,
    listFeedbackAll: false,
    listQuotes: false,
    listExpired: false,
    billingList: false,
    listOffersReport: false,
    listOffersPiaccess: false,
  },
  error: {
    listOffers: undefined,
    listFeedbackAll: undefined,
    listQuotes: undefined,
    listExpired: undefined,
    billingList: undefined,
    listOffersReport: undefined,
    listOffersPiaccess: undefined,
  },
  success: {
    listOffers: false,
    listFeedbackAll: false,
    listQuotes: false,
    listExpired: false,
    billingList: undefined,
    listOffersReport: undefined,
    listOffersPiaccess: undefined,
  },
};

const reducer = handleActions(
  {
    OFFERS: {
      GET_OFFERS: (state) => ({
        ...state,
        loading: { ...state.loading, listOffers: true },
        success: { ...state.success, listOffers: undefined },
        error: { ...state.error, listOffers: undefined },
      }),
      GET_OFFERS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listOffers: false },
            success: {
              ...state.success,
              listOffers: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listOffers: message },
            success: { ...state.success, listOffers: undefined },
            loading: { ...state.loading, listOffers: false },
          };
        },
      },
      GET_OFFERS_PIACCESS: (state) => ({
        ...state,
        loading: { ...state.loading, listOffersPiaccess: true },
        success: { ...state.success, listOffersPiaccess: undefined },
        error: { ...state.error, listOffersPiaccess: undefined },
      }),
      GET_OFFERS_PIACCESS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listOffersPiaccess: false },
            success: {
              ...state.success,
              listOffersPiaccess: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listOffersPiaccess: message },
            success: { ...state.success, listOffersPiaccess: undefined },
            loading: { ...state.loading, listOffersPiaccess: false },
          };
        },
      },

      GET_QUOTES: (state) => ({
        ...state,
        loading: { ...state.loading, listQuotes: true },
        success: { ...state.success, listQuotes: undefined },
        error: { ...state.error, listQuotes: undefined },
      }),
      GET_QUOTES_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listQuotes: false },
            success: {
              ...state.success,
              listQuotes: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listQuotes: message },
            success: { ...state.success, listQuotes: undefined },
            loading: { ...state.loading, listQuotes: false },
          };
        },
      },

      GET_QUOTES_REPORT: (state) => ({
        ...state,
        loading: { ...state.loading, listOffersReport: true },
        success: { ...state.success, listOffersReport: undefined },
        error: { ...state.error, listOffersReport: undefined },
      }),
      GET_QUOTES_REPORT_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listOffersReport: false },
            success: {
              ...state.success,
              listOffersReport: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listOffersReport: message },
            success: { ...state.success, listOffersReport: undefined },
            loading: { ...state.loading, listOffersReport: false },
          };
        },
      },

      GET_FEEDBACK: (state) => ({
        ...state,
        loading: { ...state.loading, listFeedback: true },
        success: { ...state.success, listFeedback: undefined },
        error: { ...state.error, listFeedback: undefined },
      }),
      GET_FEEDBACK_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listFeedback: false },
            success: {
              ...state.success,
              listFeedback: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listFeedback: message },
            success: { ...state.success, listFeedback: undefined },
            loading: { ...state.loading, listFeedback: false },
          };
        },
      },
      GET_FEEDBACK_ALL: (state) => ({
        ...state,
        loading: { ...state.loading, listFeedbackAll: true },
        success: { ...state.success, listFeedbackAll: undefined },
        error: { ...state.error, listFeedbackAll: undefined },
      }),
      GET_FEEDBACK_ALL_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listFeedbackAll: false },
            success: {
              ...state.success,
              listFeedbackAll: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listFeedbackAll: message },
            success: { ...state.success, listFeedbackAll: undefined },
            loading: { ...state.loading, listFeedbackAll: false },
          };
        },
      },

      GET_EXPIRED_OFFERS: (state) => ({
        ...state,
        loading: { ...state.loading, listExpired: true },
        success: { ...state.success, listExpired: undefined },
        error: { ...state.error, listExpired: undefined },
      }),
      GET_EXPIRED_OFFERS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listExpired: false },
            success: {
              ...state.success,
              listExpired: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listExpired: message },
            success: { ...state.success, listExpired: undefined },
            loading: { ...state.loading, listExpired: false },
          };
        },
      },

      GET_BILLING_LIST: (state) => ({
        ...state,
        loading: { ...state.loading, billingList: true },
        success: { ...state.success, billingList: undefined },
        error: { ...state.error, billingList: undefined },
      }),
      GET_BILLING_LIST_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, billingList: false },
            success: {
              ...state.success,
              billingList: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, billingList: message },
            success: { ...state.success, billingList: undefined },
            loading: { ...state.loading, billingList: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
