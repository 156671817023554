import { createActions } from "redux-actions";

const { offers } = createActions({
  OFFERS: {
    GET_OFFERS: (page) => ({ page }),
    GET_OFFERS_RESPONSE: (listOffers) => listOffers,

    GET_OFFERS_PIACCESS: (filters) => ({ filters }),
    GET_OFFERS_PIACCESS_RESPONSE: (listOffersPiaccess) => listOffersPiaccess,

    GET_QUOTES: (page) => ({ page }),
    GET_QUOTES_RESPONSE: (listOffers) => listOffers,

    GET_QUOTES_REPORT: (page) => ({ page }),
    GET_QUOTES_REPORT_RESPONSE: (listOffersReport) => listOffersReport,

    GET_FEEDBACK: (feedback) => ({ feedback }),
    GET_FEEDBACK_RESPONSE: (listFeedback) => listFeedback,

    GET_FEEDBACK_ALL: (feedback) => ({ feedback }),
    GET_FEEDBACK_ALL_RESPONSE: (listFeedback) => listFeedback,

    GET_EXPIRED_OFFERS: (expired) => ({ expired }),
    GET_EXPIRED_OFFERS_RESPONSE: (listExpired) => listExpired,

    GET_BILLING_LIST: (page) => ({ page }),
    GET_BILLING_LIST_RESPONSE: (billingList) => billingList,
  },
});

export default offers;
