/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Input, Pagination, Space, Table, Button } from "antd";
import Column from "antd/lib/table/Column";
import insuransersActions from "services/insuransers/actions";
import { useDispatch, useSelector } from "react-redux";
import closeSvg from "assets/images/close.svg";
import searchSvg from "assets/images/Search-purple.svg";
import ModalAdmin from "./components/ModalAdmin/ModalAdmin";

const AdminAgentOffers = () => {
  const { listAdminsTerms } = useSelector((state) => state.insuransers.success);
  const [objectSearch, setObjectSearch] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const onChange = (pageNumber) => {
    setPage(pageNumber);
    dispatch(
      insuransersActions.getAdminsTermsConditions({
        page: pageNumber,
        name: objectSearch,
      })
    );
  };
  useEffect(() => {
    dispatch(insuransersActions.getAdminsTermsConditions({ page }));
  }, [dispatch, page]);

  const handleWriting = (e) => {
    setObjectSearch(e.target.value);
  };

  const handleClear = () => {
    setObjectSearch("");
    setPage(1);
    const values = { page: 1 };
    dispatch(insuransersActions.getAdminsTermsConditions(values));
  };

  const handleSearch = (name) => {
    const values = { page: 1, name };
    setPage(1);
    dispatch(insuransersActions.getAdminsTermsConditions(values));
  };

  const itemCont = (item) => {
    const arrayFeedback = [];
    for (let index = 0; index < item.length; index++) {
      const element = item[index];
      if (
        element?.offerState === "sent" ||
        element?.offerState === "viwed" ||
        element?.offerState === "quotation"
      ) {
        arrayFeedback.push(element);
      }
    }
    return arrayFeedback.length;
  };
  const itemContFeedback = (item) => {
    const arrayFeedback = [];
    for (let index = 0; index < item.length; index++) {
      const element = item[index];
      if (
        element?.offerState === "feedback" ||
        element?.offerState === "completed" ||
        element?.offerState === "feedback_negative"
      ) {
        arrayFeedback.push(element);
      }
    }
    return arrayFeedback.length;
  };
  return (
    <div className="agents insuransers">
      <div className="insuransers-head">
        <h1 className="agents__title">Agentes dados de alta</h1>
        <Input
          placeholder="Busca por nombre de agente"
          className="search-line"
          value={objectSearch}
          onChange={(e) => handleWriting(e)}
          suffix={
            <>
              {objectSearch !== "" && (
                <Button onClick={() => handleClear()}>
                  <img
                    src={closeSvg}
                    className="site-form-item-icon"
                    alt="searcg"
                  />
                </Button>
              )}
              <Button onClick={() => handleSearch(objectSearch)}>
                <img
                  src={searchSvg}
                  className="site-form-item-icon"
                  alt="searcg"
                />
              </Button>
            </>
          }
        />
      </div>
      <div className="insuransers_table">
        <Table sticky dataSource={listAdminsTerms?.items}>
          <Column
            title="ID"
            key="id"
            dataIndex="id"
            className="item"
            width={100}
          />
          <Column
            title="Nombre"
            key="name"
            className="item-name"
            render={(_, record) => {
              return <span>{record?.user?.person?.name}</span>;
            }}
          />
          <Column
            title="Email"
            key="email"
            className="item-name"
            render={(_, record) => {
              return <span>{record?.user?.email}</span>;
            }}
          />
          <Column
            title="Ofertas recibidas"
            key="offers"
            className="item-name"
            render={(_, record) => {
              return <span>{record?.offers?.length}</span>;
            }}
          />
          <Column
            title="Ofertas activas"
            key="offersActives"
            className="item-name"
            render={(_, record) => {
              return <span>{itemCont(record?.offers)}</span>;
            }}
          />
          <Column
            title="Feedback realizado"
            key="offersFeedback"
            className="item-name"
            render={(_, record) => {
              return <span>{itemContFeedback(record?.offers)}</span>;
            }}
          />
          <Column
            title="Wallet"
            key="wallet"
            className="item-name"
            render={(_, record) => {
              return <span>${record?.budget?.subTotal} USD</span>;
            }}
          />
          <Column
            title="Accines"
            key="id"
            width={130}
            render={(_, record) => {
              return (
                <Space>
                  <ModalAdmin adminSelected={record?.offers} />
                </Space>
              );
            }}
          />
        </Table>
        <Pagination
          defaultCurrent={page}
          total={listAdminsTerms?.meta?.totalItems}
          pageSize={10}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default AdminAgentOffers;
